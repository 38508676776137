import Event01 from "assets/images/events/event_1.png";
import Event02 from "assets/images/events/event_2.png";
import Event03 from "assets/images/events/event_3.png";

export const EventItems = [
   {
      id: 1,
      imageSrc: Event01,
      title: "폭포공원 포토존",
      description: "폭포공원에 에어곰이 나타났다!",
      link: "https://www.instagram.com/p/CsGiQgIL3L-/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==",
   },
   {
      id: 2,
      imageSrc: Event02,
      title: "폭포공원 토크 콘서트",
      description: "미니유와 함께하는 토크 콘서트",
      link: "https://www.instagram.com/p/CsGixnbrInH/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==",
   },
   {
      id: 3,
      imageSrc: Event03,
      title: "토크 콘서트 사전 예약",
      description: "사전 예약자 한정 이벤트까지!",
      link: "https://www.instagram.com/p/CsGjrFnLZ_B/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==",
   },
];
