import { motion } from "framer-motion";
import styled from "styled-components";

const Container = styled(motion.div)``;

const QrCodeComponents = {
   Container,
};

export default QrCodeComponents;
