export const AreaNames: {[key:string] : string}= {
    "1": "취창업지원센터",
    "2": "평생교육원",
    "3": "평생교육원",
    "4": "홍보팀(날개단대)",
    "5": "강남브랜드안과",
    "6": "총학생회",
    "7": "총학생회",
    "8": "Gs25",
    "9": "Gs25",
    "10": "Gs25",
    "11": "패디짱",
    "12": "패디짱",
    "13": "M.O.D.E",
    "14": "교수학습개발센터",
    "15": "사주타로",
    "16": "국제교류팀",
    "17": "장애학생지원센터",
    "18": "플리마켓",
    "19": "플리마켓",
    "20": "단냥펀치",
    "21": "단디페",
    "22": "대혁단 사운팀",
    "23": "민사법학회",
    "24": "DKBS/DVOICE",
    "25": "DKBS/DVOICE",
    "26": "무혼",
    "27": "형사법학회",
    "28": "페이스페인팅 및 캐리커쳐",
    "29": "DIDIM",
    "30": "아이사랑",
    "31": "아이사랑",
    "32": "도자기 체험교실",
    "33": "도자기 체험교실",
    "34": "인권센터",
    "35": "산학협력단 SW인재양성사업팀",
    "36": "박물관",
    "37": "취창업지원센터",
    "38": "박물관",
    "39": "모닥불",
 };
