import { MapAreaType } from "./MapTypes";

export const AREAS: MapAreaType[] = [
   {
      id: 1,
      name: "메인 무대",
      namePos: {
         lat: 37.31989089483143,
         lng: 127.12727977344277,
      },
      path: [
         { lat: 37.320438286557916, lng: 127.1272665940434 },
         { lat: 37.31983313540217, lng: 127.12652660894929 },
         { lat: 37.3196278760306, lng: 127.12678292748446 },
         { lat: 37.31951924059599, lng: 127.12725940346836 },
         { lat: 37.31959536644057, lng: 127.12768824281848 },
         { lat: 37.31981574733442, lng: 127.12803271363279 },
      ],
   },
   {
      id: 2,
      name: "단과대 연합 주점",
      namePos: {
         lat: 37.31986641368062,
         lng: 127.12908483757232,
      },
      path: [
         { lat: 37.319797424259896, lng: 127.12831190953975 },
         {
            lat: 37.31925606025254,
            lng: 127.12899635965151,
         },
         {
            lat: 37.31991964857112,
            lng: 127.12984081703145,
         },
         {
            lat: 37.320479041105706,
            lng: 127.12915357733004,
         },
      ],
   },
   {
      id: 3,
      name: "주류 판매 부스",
      namePos: {
         lat: 37.320225089588234,
         lng: 127.12861160858044,
      },
      path: [
         {
            lat: 37.31992147501857,
            lng: 127.12816545550443,
         },
         {
            lat: 37.319867339799785,
            lng: 127.12823305503335,
         },
         {
            lat: 37.32044997291647,
            lng: 127.1289560926597,
         },
         {
            lat: 37.32050862598731,
            lng: 127.1288772188396,
         },
      ],
   },
   {
      id: 6,
      name: "1",
      namePos: {
         lat: 37.32104209831198,
         lng: 127.12819908931127,
      },
      path: [
         {
            lat: 37.3210365073528,
            lng: 127.128161707909,
         },
         {
            lat: 37.3210139510208,
            lng: 127.128189874808,
         },
         {
            lat: 37.3210476877375,
            lng: 127.128237880971,
         },
         {
            lat: 37.3210702471368,
            lng: 127.128206893557,
         },
      ],
   },
   {
      id: 7,
      name: "2",
      namePos: {
         lat: 37.321006669855024,
         lng: 127.12815319568625,
      },
      path: [
         {
            lat: 37.3210005180271,
            lng: 127.128113697963,
         },
         {
            lat: 37.3209757030194,
            lng: 127.128147502065,
         },
         {
            lat: 37.3210139510208,
            lng: 127.128189874808,
         },
         {
            lat: 37.3210365073528,
            lng: 127.128161707909,
         },
      ],
   },

   {
      id: 8,
      name: "3",
      namePos: {
         lat: 37.320970676708725,
         lng: 127.12810871140475,
      },
      path: [
         {
            lat: 37.3209645225763,
            lng: 127.12807132909,
         },
         {
            lat: 37.3209419632121,
            lng: 127.128102316501,
         },
         {
            lat: 37.3209757030194,
            lng: 127.128147502065,
         },
         {
            lat: 37.3210005180271,
            lng: 127.128113697963,
         },
      ],
   },
   {
      id: 9,
      name: "4",
      namePos: {
         lat: 37.320932992585526,
         lng: 127.12806563455625,
      },
      path: [
         {
            lat: 37.3209240250055,
            lng: 127.128026132102,
         },
         {
            lat: 37.3209014595482,
            lng: 127.128062760532,
         },
         {
            lat: 37.3209419632121,
            lng: 127.128102316501,
         },
         {
            lat: 37.3209645225763,
            lng: 127.12807132909,
         },
      ],
   },
   {
      id: 10,
      name: "5",
      namePos: {
         lat: 37.32089587540435,
         lng: 127.12801903306674,
      },
      path: [
         {
            lat: 37.3208902882019,
            lng: 127.127978126112,
         },
         {
            lat: 37.3208677288618,
            lng: 127.128009113521,
         },
         {
            lat: 37.3209014595482,
            lng: 127.128062760532,
         },
         {
            lat: 37.3209240250055,
            lng: 127.128026132102,
         },
      ],
   },

   {
      id: 11,
      name: "6",
      namePos: {
         lat: 37.320861573929,
         lng: 127.12797243639649,
      },
      path: [
         {
            lat: 37.320861050436,
            lng: 127.12793576882,
         },
         {
            lat: 37.3208272282163,
            lng: 127.127966737133,
         },
         {
            lat: 37.3208677288618,
            lng: 127.128009113521,
         },
         {
            lat: 37.3208902882019,
            lng: 127.127978126112,
         },
      ],
   },
   {
      id: 12,
      name: "7",
      namePos: {
         lat: 37.3208350710529,
         lng: 127.12800482736375,
      },
      path: [
         {
            lat: 37.3208272282163,
            lng: 127.127966737133,
         },
         {
            lat: 37.3208069184062,
            lng: 127.128000548846,
         },
         {
            lat: 37.3208384087273,
            lng: 127.128042909955,
         },
         {
            lat: 37.3208677288618,
            lng: 127.128009113521,
         },
      ],
   },
   {
      id: 13,
      name: "8",
      namePos: {
         lat: 37.3207940888274,
         lng: 127.12788700148751,
      },
      path: [
         {
            lat: 37.3207845511855,
            lng: 127.127853844314,
         },
         {
            lat: 37.3207665000821,
            lng: 127.127882018844,
         },
         {
            lat: 37.3208002460732,
            lng: 127.127921563191,
         },
         {
            lat: 37.3208250579688,
            lng: 127.127890579601,
         },
      ],
   },
   {
      id: 14,
      name: "9",
      namePos: {
         lat: 37.32075921958187,
         lng: 127.12784463474576,
      },
      path: [
         {
            lat: 37.3207553225135,
            lng: 127.127803025613,
         },
         {
            lat: 37.3207305045464,
            lng: 127.127839650212,
         },
         {
            lat: 37.3207665000821,
            lng: 127.127882018844,
         },
         {
            lat: 37.3207845511855,
            lng: 127.127853844314,
         },
      ],
   },
   {
      id: 15,
      name: "10",
      namePos: {
         lat: 37.3207237925081,
         lng: 127.12779733120799,
      },
      path: [
         {
            lat: 37.3207148217931,
            lng: 127.127760649399,
         },
         {
            lat: 37.3206945211794,
            lng: 127.127785999608,
         },
         {
            lat: 37.3207305045464,
            lng: 127.127839650212,
         },
         {
            lat: 37.3207553225135,
            lng: 127.127803025613,
         },
      ],
   },
   {
      id: 16,
      name: "11",
      namePos: {
         lat: 37.3206793482529,
         lng: 127.1277563585945,
      },
      path: [
         {
            lat: 37.3206540234972,
            lng: 127.127740802936,
         },
         {
            lat: 37.3206540265419,
            lng: 127.127737982435,
         },
         {
            lat: 37.3206945211794,
            lng: 127.127785999608,
         },
         {
            lat: 37.3207148217931,
            lng: 127.127760649399,
         },
      ],
   },
   {
      id: 17,
      name: "12",
      namePos: {
         lat: 37.320641668562324,
         lng: 127.127709051335,
      },
      path: [
         {
            lat: 37.3206383376345,
            lng: 127.127664622724,
         },
         {
            lat: 37.3206202865757,
            lng: 127.127692797245,
         },
         {
            lat: 37.3206540265419,
            lng: 127.127737982435,
         },
         {
            lat: 37.3206540234972,
            lng: 127.127740802936,
         },
      ],
   },
   {
      id: 18,
      name: "13",
      namePos: {
         lat: 37.3206107526769,
         lng: 127.1276561145535,
      },
      path: [
         {
            lat: 37.3206045976401,
            lng: 127.127619437577,
         },
         {
            lat: 37.3205797888573,
            lng: 127.127647600668,
         },
         {
            lat: 37.3206202865757,
            lng: 127.127692797245,
         },
         {
            lat: 37.3206383376345,
            lng: 127.127664622724,
         },
      ],
   },
   {
      id: 19,
      name: "14",
      namePos: {
         lat: 37.32056407251475,
         lng: 127.127599625545,
      },
      path: [
         {
            lat: 37.3205281103254,
            lng: 127.127526231683,
         },
         {
            lat: 37.3205437932362,
            lng: 127.127605232252,
         },
         {
            lat: 37.3205797888573,
            lng: 127.127647600668,
         },
         {
            lat: 37.3206045976401,
            lng: 127.127619437577,
         },
      ],
   },
   {
      id: 20,
      name: "15",
      namePos: {
         lat: 37.320536514168325,
         lng: 127.127566438043,
      },
      path: [
         {
            lat: 37.3205281103254,
            lng: 127.127526231683,
         },
         {
            lat: 37.3205055480618,
            lng: 127.127560039574,
         },
         {
            lat: 37.3205437932362,
            lng: 127.127605232252,
         },
         {
            lat: 37.3205686050499,
            lng: 127.127574248663,
         },
      ],
   },
   {
      id: 21,
      name: "16",
      namePos: {
         lat: 37.32050221099138,
         lng: 127.12752125204726,
      },
      path: [
         {
            lat: 37.3205011249735,
            lng: 127.127483878573,
         },
         {
            lat: 37.3204740606048,
            lng: 127.127514858359,
         },
         {
            lat: 37.3205055480618,
            lng: 127.127560039574,
         },
         {
            lat: 37.3205281103254,
            lng: 127.127526231683,
         },
      ],
   },
   {
      id: 22,
      name: "17",
      namePos: {
         lat: 37.320475708997606,
         lng: 127.12755293789526,
      },
      path: [
         {
            lat: 37.3204740606048,
            lng: 127.127514858359,
         },
         {
            lat: 37.3204469931877,
            lng: 127.127548658616,
         },
         {
            lat: 37.3204762341361,
            lng: 127.127588195032,
         },
         {
            lat: 37.3205055480618,
            lng: 127.127560039574,
         },
      ],
   },
//   {
//      id: 23,
//      name: "18",
//      namePos: {
//         lat: 37.320475708997606,
//         lng: 127.12755293789526,
//      },
//      path: [
//         {
//            lat: 37.3204740606048,
//            lng: 127.127514858359,
//         },
//         {
//            lat: 37.3204469931877,
//            lng: 127.127548658616,
//         },
//         {
//            lat: 37.3204762341361,
//            lng: 127.127588195032,
//         },
//         {
//            lat: 37.3205055480618,
//            lng: 127.127560039574,
//         },
//      ],
//   },
//   {
//      id: 24,
//      name: "19",
//      namePos: {
//         lat: 37.320475708997606,
//         lng: 127.12755293789526,
//      },
//      path: [
//         {
//            lat: 37.3204740606048,
//            lng: 127.127514858359,
//         },
//         {
//            lat: 37.3204469931877,
//            lng: 127.127548658616,
//         },
//         {
//            lat: 37.3204762341361,
//            lng: 127.127588195032,
//         },
//         {
//            lat: 37.3205055480618,
//            lng: 127.127560039574,
//         },
//      ],
//   },
   {
      id: 25,
      name: "20",
      namePos: {
         lat: 37.32110414729843,
         lng: 127.12810400204799,
      },
      path: [
         {
            lat: 37.3211086814645,
            lng: 127.128077214758,
         },
         {
            lat: 37.321047874069,
            lng: 127.128065829485,
         },
         {
            lat: 37.3211198649579,
            lng: 127.128150567361,
         },
         {
            lat: 37.3211401687023,
            lng: 127.128122396588,
         },
      ],
   },
   {
      id: 26,
      name: "21",
      namePos: {
         lat: 37.321074331173676,
         lng: 127.12807574620874,
      },
      path: [
         {
            lat: 37.3210614109034,
            lng: 127.128046108801,
         },
         {
            lat: 37.321047874069,
            lng: 127.128065829485,
         },
         {
            lat: 37.3210793582578,
            lng: 127.128113831791,
         },
         {
            lat: 37.3211086814645,
            lng: 127.128077214758,
         },
      ],
   },
   {
      id: 27,
      name: "22",
      namePos: {
         lat: 37.32104002819422,
         lng: 127.12803055966175,
      },
      path: [
         {
            lat: 37.3210366935736,
            lng: 127.127989656448,
         },
         {
            lat: 37.3210141342309,
            lng: 127.128020643913,
         },
         {
            lat: 37.321047874069,
            lng: 127.128065829485,
         },
         {
            lat: 37.3210614109034,
            lng: 127.128046108801,
         },
      ],
   },
   {
      id: 28,
      name: "23",
      namePos: {
         lat: 37.321006292918646,
         lng: 127.12798114333924,
      },
      path: [
         {
            lat: 37.3209984485502,
            lng: 127.12794446328,
         },
         {
            lat: 37.3209758953199,
            lng: 127.127969809716,
         },
         {
            lat: 37.3210141342309,
            lng: 127.128020643913,
         },
         {
            lat: 37.3210366935736,
            lng: 127.127989656448,
         },
      ],
   },
   {
      id: 29,
      name: "24",
      namePos: {
         lat: 37.320964124171525,
         lng: 127.12791902044201,
      },
      path: [
         {
            lat: 37.3209556953044,
            lng: 127.127902083036,
         },
         {
            lat: 37.3209264575116,
            lng: 127.127859725736,
         },
         {
            lat: 37.3209758953199,
            lng: 127.127969809716,
         },
         {
            lat: 37.3209984485502,
            lng: 127.12794446328,
         },
      ],
   },
   {
      id: 30,
      name: "25",
      namePos: {
         lat: 37.32092869180123,
         lng: 127.12787665262451,
      },
      path: [
         {
            lat: 37.3209264575116,
            lng: 127.127859725736,
         },
         {
            lat: 37.3209061568773,
            lng: 127.12788507599,
         },
         {
            lat: 37.3209264575116,
            lng: 127.127859725736,
         },
         {
            lat: 37.3209556953044,
            lng: 127.127902083036,
         },
      ],
   },
   {
      id: 31,
      name: "26",
      namePos: {
         lat: 37.32095406073387,
         lng: 127.127851310944,
      },
      path: [
         {
            lat: 37.3209535311091,
            lng: 127.127820284351,
         },
         {
            lat: 37.320933221341,
            lng: 127.12785409616,
         },
         {
            lat: 37.3209534671134,
            lng: 127.127879515117,
         },
         {
            lat: 37.320976023372,
            lng: 127.127851348148,
         },
      ],
   },
   {
      id: 32,
      name: "27",
      namePos: {
         lat: 37.32089163623145,
         lng: 127.1277729358915,
      },
      path: [
         {
            lat: 37.3209017979594,
            lng: 127.12774968384,
         },
         {
            lat: 37.3208679757885,
            lng: 127.127780652253,
         },
         {
            lat: 37.3208949732185,
            lng: 127.127811723633,
         },
         {
            lat: 37.3209017979594,
            lng: 127.12774968384,
         },
      ],
   },
   {
      id: 33,
      name: "28",
      namePos: {
         lat: 37.3208561154807,
         lng: 127.1278123629625,
      },
      path: [
         {
            lat: 37.3208522199242,
            lng: 127.127769343522,
         },
         {
            lat: 37.3208319132166,
            lng: 127.127800334792,
         },
         {
            lat: 37.320870164391,
            lng: 127.127839886768,
         },
         {
            lat: 37.320870164391,
            lng: 127.127839886768,
         },
      ],
   },
   {
      id: 34,
      name: "29",
      namePos: {
         lat: 37.32082519888053,
         lng: 127.127760131115,
      },
      path: [
         {
            lat: 37.3208207355938,
            lng: 127.127721341504,
         },
         {
            lat: 37.3207959267875,
            lng: 127.127749504642,
         },
         {
            lat: 37.3208319132166,
            lng: 127.127800334792,
         },
         {
            lat: 37.3208522199242,
            lng: 127.127769343522,
         },
      ],
   },
   {
      id: 35,
      name: "30",
      namePos: {
         lat: 37.320786411164875,
         lng: 127.12769589882825,
      },
      path: [
         {
            lat: 37.3207824843794,
            lng: 127.127681789614,
         },
         {
            lat: 37.3207464978988,
            lng: 127.127630959553,
         },
         {
            lat: 37.3207959267875,
            lng: 127.127749504642,
         },
         {
            lat: 37.3208207355938,
            lng: 127.127721341504,
         },
      ],
   },
   {
      id: 36,
      name: "31",
      namePos: {
         lat: 37.32074872765668,
         lng: 127.12765211715276,
      },
      path: [
         {
            lat: 37.3207464978988,
            lng: 127.127630959553,
         },
         {
            lat: 37.3207194304497,
            lng: 127.127664759891,
         },
         {
            lat: 37.3207464978988,
            lng: 127.127630959553,
         },
         {
            lat: 37.3207824843794,
            lng: 127.127681789614,
         },
      ],
   },
   {
      id: 37,
      name: "32",
      namePos: {
         lat: 37.32071553179025,
         lng: 127.1276245610465,
      },
      path: [
         {
            lat: 37.3207105022729,
            lng: 127.127588591052,
         },
         {
            lat: 37.3206856965396,
            lng: 127.12761393369,
         },
         {
            lat: 37.3207194304497,
            lng: 127.127664759891,
         },
         {
            lat: 37.3207464978988,
            lng: 127.127630959553,
         },
      ],
   },
   {
      id: 38,
      name: "33",
      namePos: {
         lat: 37.320680667012596,
         lng: 127.1275779637165,
      },
      path: [
         {
            lat: 37.3206722601336,
            lng: 127.12754057778,
         },
         {
            lat: 37.3206542091043,
            lng: 127.127568752344,
         },
         {
            lat: 37.3206856965396,
            lng: 127.12761393369,
         },
         {
            lat: 37.3207105022729,
            lng: 127.127588591052,
         },
      ],
   },
   {
      id: 39,
      name: "34",
      namePos: {
         lat: 37.320642424868375,
         lng: 127.12752995047076,
      },
      path: [
         {
            lat: 37.3206295128179,
            lng: 127.127492556947,
         },
         {
            lat: 37.3206137174177,
            lng: 127.127517914812,
         },
         {
            lat: 37.3206542091043,
            lng: 127.127568752344,
         },
         {
            lat: 37.3206722601336,
            lng: 127.12754057778,
         },
      ],
   },
   {
      id: 40,
      name: "35",
      namePos: {
         lat: 37.32060418194548,
         lng: 127.12748264239525,
      },
      path: [
         {
            lat: 37.3205980253358,
            lng: 127.127447375686,
         },
         {
            lat: 37.3205754722105,
            lng: 127.127472722136,
         },
         {
            lat: 37.3206137174177,
            lng: 127.127517914812,
         },
         {
            lat: 37.3206295128179,
            lng: 127.127492556947,
         },
      ],
   },
   {
      id: 41,
      name: "36",
      namePos: {
         lat: 37.32057156817032,
         lng: 127.12743745925076,
      },
      path: [
         {
            lat: 37.3205665378363,
            lng: 127.127402194462,
         },
         {
            lat: 37.3205462372987,
            lng: 127.127427544719,
         },
         {
            lat: 37.3205754722105,
            lng: 127.127472722136,
         },
         {
            lat: 37.3205980253358,
            lng: 127.127447375686,
         },
      ],
   },
   {
      id: 42,
      name: "37",
      namePos: {
         lat: 37.3210189635454,
         lng: 127.128241357814,
      },
      path: [
         {
            lat: 37.3210251894646,
            lng: 127.12821245806,
         },
         {
            lat: 37.3209913580029,
            lng: 127.128251887858,
         },
         {
            lat: 37.3210116189766,
            lng: 127.128263204367,
         },
         {
            lat: 37.3210476877375,
            lng: 127.128237880971,
         },
      ],
   },
];
